
import AppNotifications from '@/components/Taxonomy/elements/AppNotifications.vue';
import { sendReadyMessage } from './js/powerup';
export default {
  name: 'App',
  components: {
    AppNotifications
  },
  created: async function () {
    // Only send the ready message for search and edit pages. It should NOT be sent for PowerUp view pages.
    // The ready message is sent in the component itself for views, so that the correct height can be passed in.
    let powerup_views = [
      '/investment/view',
      '/taxonomy/view',
      '/authorDisclosures/view',
      '/files/view'
    ];
    const url = new URL(location.href);
    if (!powerup_views.includes(url.pathname)) {
      let height = document.documentElement.scrollHeight;
      sendReadyMessage(url.pathname, height);
    }
  }
};
